import { Images } from "../images"

export const NavList = [
  { 
    id: 1, 
    title: 'О нас',
    path: '/about/'
  },
  {
    id: 2, 
    title: 'Стеки',
    path: '/stacks/'
  },
  {
    id: 3, 
    title: 'Портфолио',
    path: '/portfolio/'
  }
]


export const Portfolio = [
  {
    id: 1, 
    title: 'TechnoFoods',
    image: Images.TechnoFoods,
    image2: Images.TechnoFoods1 ,
    stacks: [
      'Design',
      'Frontend',
      'Backend'
    ],
    link: 'https://technofoods.kg'
  },
  {
    id: 2, 
    title: 'Turkmed',
    image: Images.Turkmed,
    image2: Images.Turkmed1 ,
    stacks: [
      'Frontend',
      'Backend'
    ],
    link: 'https://turkmed-web.netlify.app/'
  },
  {
    id: 3, 
    title: 'Kelvin',
    image: Images.Kelvin,
    image2: Images.Kelvin1  ,
    stacks: [
      'Design',
      'Frontend',
      'Backend'
    ],
    link: 'https://kelvin-store.netlify.app/'
  },
  {
    id: 4, 
    title: 'GSR',
    image: Images.GSR,
    image2: Images.GSR1 ,
    stacks: [
      'Design',
      'Frontend',
    ],
    link: ''
  },
  {
    id: 5, 
    title: 'ВШМОП',
    image: Images.VSHMOP,
    image2: Images.VSHMOP1  ,
    stacks: [
      'Design',
      'Frontend',
    ],
    link: ''
  },
  {
    id: 6, 
    title: 'Finprogress',
    image: Images.Finprogress,
    image2: Images.Finprogress1 ,
    stacks: [
      'Frontend'
    ],
    link: 'https://finprogress.netlify.app/'
  },
  {
    id: 7, 
    title: 'CityFish',
    image: Images.Cityfish,
    image2: Images.Cityfish1  ,
    stacks: [
      'Design',
      'Frontend',
      'Backend'
    ],
    link: 'https://cityfishh.ru/'
  },
  {
    id: 8, 
    title: 'CityFish - Admin',
    image: Images.CityfishAdmin,
    image2: Images.CityfishAdmin1 ,
    stacks: [
      'Frontend',
      'Backend'
    ],
    link: ''
  },
  {
    id: 9, 
    title: 'До границы',
    image: Images.DoGranicy,
    image2: Images.DoGranicy1 ,
    stacks: [
      'Frontend',
      'Backend'
    ],
    link: 'https://do-granicy.ru/'
  },
  {
    id: 10, 
    title: 'Amway',
    image: Images.Amway,
    image2: Images.Amway1 ,
    stacks: [
      'Frontend',
      'Backend' 
    ],
    link: 'https://amway-site.netlify.app/'
  },
]


export const SEOKeys = [
  "сайты и услуги дизайна в кыргызстане",
  "заказать сайт в кыргызстане",
  "в кыргызстане",
  "заказать сайт",
  "заказать дизайн",
  "заказать визитки",
  "сайт",
  "дизайн",
  "графический дизайн",
  "кыргызстан",
  "россия",
  "казахстан",
  "узбекистан",
  "графический дизайнер",
  "визитки на заказ",
  "сайт на заказ",
  "печать визиток",
  "сайт заказать",
  "сайта",
  "заказать создание сайта",
  "заказ сайта",
  "изготовление визиток",
  "визитки заказать",
  "создание сайтов на заказ",
  "заказать визитки недорого",
  "сделать визитки",
  "сайт под заказ",
  "заказ визиток",
  "граф дизайнер",
  "дизайнер графический",
  "grafik dizayner",
  "создание сайта заказать",
  "разработка сайтов на заказ",
  "графические дизайнеры",
  "разработка сайта заказать",
  "разработка сайтов заказать",
  "компьютерный дизайн",
  "визитки на заказ недорого",
  "визитки недорого",
  "визитки под заказ",
  "визитная карточка заказать",
  "графики дизайн",
  "графически дизайн",
  "графические дизайны",
  "графический",
  "графического",
  "графического дизайна",
  "дизайн графики",
  "дизайн заказать",
  "дизайнер графики",
  "дизайнеры графики",
  "дизайнеры графические",
  "закажу сайт",
  "заказ визитных карточек",
  "заказ визиток цена",
  "заказ на сайт",
  "заказ на создание сайта",
  "заказ печати визиток",
  "заказ разработка сайта",
  "заказ сайт",
  "заказать визитки в",
  "заказать визитки рядом со мной",
  "заказать визитки цена",
  "заказать визитную карточку",
  "заказать изготовление визиток",
  "заказать изготовление сайта",
  "заказать печать визиток",
  "заказать сайт для компании",
  "заказать сайт компании",
  "заказать сайт на заказ",
  "изготовление визиток на заказ",
  "изготовление визиток недорого",
  "изготовление и печать визиток",
  "изготовление печать визиток",
  "изготовление сайта заказать",
  "изготовление сайта на заказ",
  "казакстан",
  "казахстане",
  "напечатать визитки недорого",
  "напечатать визитки цена",
  "о графическом дизайне",
  "печать визиток заказать",
  "печать визиток на заказ",
  "печать визиток недорого",
  "печать визиток типография",
  "печать визиток цена",
  "про графический дизайн",
  "программный дизайн",
  "разработка сайта на заказ",
  "разработка сайта под заказ",
  "разработка сайтов под заказ",
  "распечатать визитки цена",
  "сделать визитки на заказ",
  "сделать визитки недорого",
  "создание сайтов под заказ",
  "типография визитки цена",
  "типография заказ визиток",
  "типография заказать визитки",
  "типография изготовление визиток",
  "типография печать визиток",
  "узбе",
  "узбекистана",
  "заказ сайты",
]