import React from 'react'
import c from './services.module.scss'
import { Link } from 'react-router-dom'

const Services = ({ServicesRef}) => {
  return (
    <div className={c.services} ref={ServicesRef}>
      <div className={c.title}>
        <h1>Наши возможности</h1>
        <h2>В нашей IT компании мы гордимся широким спектром технологий и инструментов, которые используем для разработки современных и надежных решений</h2>
      </div>
      <div className={c.cards}>
        <div className={c.card}>
          <h1>
            Design
          </h1>
          <h2>
            В нашей IT компании мы верим, что качественный дизайн - это ключ к успешному бизнесу. Мы создаем потрясающие и функциональные дизайны, которые не только привлекают внимание, но и эффективно передают вашу уникальность и ценности.
          </h2>
          <div>
            <h1>
              8
            </h1>
            <h2>Проектов</h2>
          </div>
          <Link to={'https://t.me/sattarzanov'}>
            <button>
              Обсудить проект
            </button>
          </Link>
        </div>
        <div className={c.card}>
          <h1>
            Frontend
          </h1>
          <h2>
            В нашей IT компании мы специализируемся на создании интуитивно понятных, динамичных и привлекательных интерфейсов, которые обеспечивают великолепный пользовательский опыт. Мы используем передовые технологии и лучшие практики, чтобы ваши веб-приложения были не только красивыми, но и быстрыми, безопасными и надежными.
          </h2>
          <div>
            <h1>
              10
            </h1>
            <h2>Проектов</h2>
          </div>
          <Link to={'https://t.me/sattarzanov'}>
            <button>
              Обсудить проект
            </button>
          </Link>
        </div>
        <div className={c.card}>
          <h1>
            Backend
          </h1>
          <h2>
            В нашей IT компании мы специализируемся на разработке надежных, масштабируемых и безопасных backend решений, которые обеспечивают стабильную работу ваших веб-приложений и систем. Мы используем передовые технологии и лучшие практики для создания высокопроизводительных backend решений, которые соответствуют вашим бизнес-потребностям.
          </h2>
          <div>
            <h1>
              6
            </h1>
            <h2>Проектов</h2>
          </div>
          <Link to={'https://t.me/sattarzanov'}>
            <button>
              Обсудить проект
            </button>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Services